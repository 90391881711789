import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from "react";

type InsightsTab = "Macro" | "Equities";

interface InsightsContextType {
  activeTab: InsightsTab;
  setActiveTab: (tab: InsightsTab) => void;
}

const defaultInsightsState: InsightsContextType = {
  activeTab: "Macro",
  setActiveTab: () => {},
};

const InsightsContext =
  createContext<InsightsContextType>(defaultInsightsState);

export const useInsightsContext = () => useContext(InsightsContext);

interface InsightsProviderProps {
  children: ReactNode;
}

export const InsightsProvider: React.FC<InsightsProviderProps> = ({
  children,
}) => {
  const [activeTab, setActiveTab] = useState<InsightsTab>("Macro");

  const value = useMemo(
    () => ({
      activeTab,
      setActiveTab,
    }),
    [activeTab],
  );

  return (
    <InsightsContext.Provider value={value}>
      {children}
    </InsightsContext.Provider>
  );
};
